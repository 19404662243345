import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import axios from "axios"
import { postFormData } from "./api/Api"
import * as qs from "query-string"
import { useLocation } from "@reach/router";


const SalesForceForm = (props) => {
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [salesFormUrl, setsalesFormUrl] = useState("");
  const [utmData, setUtmData] = useState({
    utm_source: "",
    utm_medium: "",
    utm_term: "",
    utm_campaign: "",
  });
  const myRef = React.createRef();

  useEffect(() => {
    setsalesFormUrl(location.href); 
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUtmData({
      utm_source: urlParams.get("utm_source") || "",
      utm_medium: urlParams.get("utm_medium") || "",
      utm_term: urlParams.get("utm_term") || "",
      utm_campaign: urlParams.get("utm_campaign") || "",
    });
  }, []);

  if (typeof window !== 'undefined') {
    localStorage.setItem("salesFormUrl", salesFormUrl);
  }

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } else {
      // event.preventDefault();
      // const formsdata = (event.target);
      // const json = {}
      // Object.keys(formsdata).map(key => (
      //   json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      // ))

      // let formData = new FormData();
      // formData.append('data', JSON.stringify({
      //   form_name: "salesforce",
      //   name: json['00N0600000MDmMQ'],
      //   extra: json
      // }));
      // postFormData(formData).then(apiRes => {
      //   const salesForceApi = {
      //     url: "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D06000001W3A7",
      //     method: "post",
      //     headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
      //     withCredentials: false,
      //     data: qs.stringify(json),
      //   }

      //   axios(salesForceApi)
      //     .then(response => {
      //       console.log('formsubmit data stored', response)

      //     })
      //     .catch(err =>
      //       console.log(err)
      //     );
      // });
      // setValidated(false);
      // // reset form
      // const form = event.target
      // form.reset();
      // setShowerror(false);
      // setThankyou(true);
    }
  };

  return (
    <div className={`form stbform saleforce-form ${props.className}`}>
      {showerror && <div className="alert-error">
        <p>Highlighted fields are required</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>Thank you for contacting us. A member of our team will contact you shortly.</p>
        <p><a href={props.guide} target="_blank" download>Click here to download the brochure.</a></p>
      </div>}
      <Form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D06000001W3A7" method="POST" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" id="utm_source" maxlength="255" name="utm_source__c" size="20" value={utmData.utm_source} />
        <input type="hidden" id="utm_medium" maxlength="255" name="utm_medium__c" size="20" value={utmData.utm_medium} />
        <input type="hidden" id="utm_term" maxlength="255" name="utm_term__c" size="20" value={utmData.utm_term} />
        <input type="hidden" id="utm_campaign" maxlength="255" name="utm_campaign__c" size="20" value={utmData.utm_campaign} />

        <input type="hidden" name="orgid" value="00D06000001W3A7" />
        <input id="00N0600000MDmMQ" maxlength="255" name="00N0600000MDmMQ" type="hidden" value={props.propertyName} />
        <input type="hidden" name="retURL" value={props.retURL} />
        <div className="form-group">
          <input id="00N0600000MDmLw" maxlength="255" name="00N0600000MDmLw" size="20" type="text" fdprocessedid="buwz4" placeholder="First Name" className="form-control" required />
        </div>

        <div className="form-group">
          <input id="00N0600000MDmM1" maxlength="255" name="00N0600000MDmM1" size="20" type="text" fdprocessedid="dwwugp" placeholder="Last Name" className="form-control" required />
        </div>
        <div className="form-group">
          <input id="00N0600000MDmBb" maxlength="80" name="00N0600000MDmBb" size="20" type="email" fdprocessedid="go46z" placeholder="Email" className="form-control" required />
        </div>
        <div className="form-group">
          <input id="00N0600000MDmBW" maxlength="40" name="00N0600000MDmBW" size="20" type="tel" fdprocessedid="lojkgj" placeholder="Phone" className="form-control" required />
        </div>
        <div className="form-group">
          <select id="00N0600000MDpkK" name="00N0600000MDpkK" title="Country " fdprocessedid="gnbj5s" class="form-control" required>
            <option value="">Country</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burma">Burma</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
            <option value="Republic of the Congo">Republic of the Congo</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="North Korea">North Korea</option>
            <option value="South Korea">South Korea</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia">Micronesia</option>
            <option value="Moldova">Moldova</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Morocco">Morocco</option>
            <option value="Monaco">Monaco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Qatar">Qatar</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="São Tomé">São Tomé</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United States">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </div>
        <input type="submit" name="submit" value={props.buttonText ? props.buttonText : "submit"} className="btn btn-success btn" fdprocessedid="zz7ka" />
      </Form>
      <div ref={myRef} />
    </div>
  );
}

export default SalesForceForm